import React from 'react';
import { SplitSection, Layout, Hero, HeroImage } from '@components';
import { PageProps, graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { motion } from 'framer-motion';

interface IndexProps extends PageProps {
    data: any;
    pageContext: { lang: string };
}

const IndexPage: React.FC<IndexProps> = ({ data, pageContext: { lang } }) => {
    const Maincontent = data.strapiIndexPage.childStrapiIndexPageMaincontentTextnode.childMdx.body;
    const SplitSectionData = data.strapiIndexPage.SplitSection;

    return (
        <Layout className="overflow-x-clip " lang={lang}>
            <motion.div
                animate={{ opacity: 1 }}
                initial={{ opacity: 0 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}>
                <HeroImage lang={lang} />

                <div className="md:pt-0 text-center index ">
                    <MDXRenderer className="indexPage ">{Maincontent}</MDXRenderer>
                </div>
            </motion.div>
            <div className="my-12 flex flex-col w-full items-center justify-center m-auto gap-12">
                {SplitSectionData.map((section, i) => (
                    <SplitSection key={i} index={i} data={section} />
                ))}
            </div>
        </Layout>
    );
};

export default IndexPage;

export const query = graphql`
    query IndexPage($locale: String!) {
        # strapiPage(Title: { eq: "O festivalu" }) {
        #     localizations {
        #         data {
        #             attributes {
        #                 Title
        #             }
        #         }
        #     }
        #     excerpt: childStrapiPageContentTextnode {
        #         childMdx {
        #             rawBody
        #         }
        #     }
        #     excerptEn: childStrapiPageContentTextnodeEn {
        #         childMdx {
        #             rawBody
        #         }
        #     }
        #     Title
        # }
        # strapiIndexPageContent {
        #     childMdx {
        #         body
        #     }
        # }

        strapiIndexPage(locale: { eq: $locale }) {
            childStrapiIndexPageMaincontentTextnode {
                childMdx {
                    body
                }
            }
            YoutubeVideoID
            YoutubeChannelID
            SplitSection {
                Description
                Title
                Link {
                    Slug
                    Title
                }
                Image {
                    localFile {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
        }
    }
`;
